import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import animationData from './wifi-animation.json';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 60px !important;
    height: 60px !important;
  }
`;

const WifiAnimation = (): JSX.Element => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: animationContainer.current as HTMLDivElement,
      animationData,
      loop: true,
      autoplay: true,
      renderer: 'svg',
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    return () => {
      animationInstance.destroy();
    };
  }, []);

  return <Container data-testid="mock-lottie" ref={animationContainer} />;
};

export default WifiAnimation;
