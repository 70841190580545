type Payload = {
  activityParameters: ActivityParameters;
  devParameters?: DevParameters;
  regionParameters: RegionParameters;
  userParameters: UserParameters;
  solverUrl: string;
};

type DevParameters = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  actions: Record<string, Function>;
  isDevelopment: boolean;
  buildLogUrl: string | undefined;
};

type ActivityParameters = {
  codename: string;
  description: string;
  id: string;
  maxDuration: number;
  minDuration: number;
  minStatements: number;
  pack: string;
  sceneName: string;
  type: string;
  activityName?: string;
  unityActivity?: UnityActivity;
  buildlog: boolean | undefined;
};

// TODO it is not used
type Activity = {
  id: string;
  type: string;
  statements: Statement['id'][];
};

type UnityActivity = {
  codename: string;
  description: string;
  is_motor: boolean;
  max_duration: number;
  min_duration: number;
  min_statements: number;
  order: number;
  pack: number;
  scene_name: string;
  variation: number;
};

type RegionParameters = {
  clock: string;
  currency: string;
  decimalSeparator: string;
  decimalSeparatorAudio: string;
  metricSystem: string;
  shortDivisionSign: string;
  shortMultiplicationSign: string;
  shortMultiplicationSignCollection: { primary: string; secondary: string };
  temperature: string;
  thousandSeparator: string;
  verticalDivisionAlgorithm: string;
  verticalMultiplicationPosition: string;
  verticalOperationsParameters: {
    verticalDivisionAlgorithm: string;
    verticalMultiplicationPosition: string;
    verticalSubtractionPosition: string;
    verticalSumPosition: string;
  };
  verticalSubtractionPosition: string;
  verticalSumPosition: string;
};

type UserParameters = {
  language: string;
  platformUrl: string;
  school?: string;
  solverUrl: string;
  stage: string;
  Tokens: {
    AccessToken: string;
    RefreshToken: string;
    IdToken: string;
    clientID: string;
  };
  userId: string;
  userToken: string;
  uid?: string;
};

const RESPONSE_STATUS_TYPE = {
  ERROR: 'error',
  IDLE: 'idle',
  LOADING: 'loading',
  READY: 'ready',
} as const;

type ResponseStatusType =
  (typeof RESPONSE_STATUS_TYPE)[keyof typeof RESPONSE_STATUS_TYPE];

type ActivityState = {
  actions?: {
    resolveStatement: (
      answer: string[] | string[][],
      currentStatement: Statement,
    ) => Promise<Feedback>;
    selectNextStatement: ({
      totalStatements,
    }: {
      totalStatements: number;
    }) => void;
  };
  currentStatement?: Statement;
  statements?: Statement[];
  title: string;
  totalStatements: number;
};

type RequestState = {
  slowRequest: false;
};

type Feedback = {
  isCorrect: boolean;
  solution: string;
  slotsCorrectness: {
    correct: boolean;
    globalChecks: {
      syntaxMath: number;
      equivalent_symbolic: number;
    };
    compoundChecks: object[];
    correctAnswers: string[];
  }[];
};
const STATIC_TABLE_CELL_VARIANT = {
  EMPTY: 'empty',
  TEXT: 'text',
  INPUT: 'input',
} as const;

type EmptyField = {
  type: typeof STATIC_TABLE_CELL_VARIANT.EMPTY;
  value: null;
};

type TextField = {
  type: typeof STATIC_TABLE_CELL_VARIANT.TEXT;
  value: string;
  background: boolean;
  align: 'left' | 'center' | 'right';
};

type InputField = {
  type: typeof STATIC_TABLE_CELL_VARIANT.INPUT;
  value: null | string;
};

type InteractiveTableFields = EmptyField | TextField | InputField;
type StaticTableFields = EmptyField | TextField;
type TableOptions = {
  headerColumn?: boolean;
  headerRow?: boolean;
};

type InteractiveTable = {
  options: TableOptions;
  fields: InteractiveTableFields[][];
};

type StaticTable = {
  options: TableOptions;
  fields: StaticTableFields[][];
};

type Statement = {
  frontLabel?: string;
  id: string;
  image?: string;
  layout: Layout;
  seed: number;
  statementText: string;
  type?: string;
  wirisDefinition?: string;
  table?: InteractiveTable;
  staticTable?: StaticTable;
};

type Metrics = {
  correct: number;
  incorrect: number;
};

type BaseEvent<EventType, Args = Record<string, never>> = {
  arguments: { activityId: string } & Args;
  eventId: EventType;
};

type ErrorArguments = {
  error: string | object;
};

type ActivityIsCompletedArguments = {
  metrics: string;
  totalTime: number;
};

type ConnectionAppletTimeoutArguments = {
  source?: string;
};

type EventArguments = {
  payload: object;
  eventId: string;
};

type EmptyArguments = Record<never, never>;

type MessageType =
  | BaseEvent<'ActivityIsCompleted', ActivityIsCompletedArguments>
  | BaseEvent<'AppIsClosed', EmptyArguments>
  | BaseEvent<'ConnectionAppletTimeout', ConnectionAppletTimeoutArguments>
  | BaseEvent<'Error', ErrorArguments>
  | BaseEvent<'SendEvent', EventArguments>;

const LAYOUTS = {
  INPUT_ANSWER: 'INPUT_ANSWER',
  INTERACTIVE_GRAPHIC: 'INTERACTIVE_GRAPHIC',
  INTERACTIVE_TABLE: 'INTERACTIVE_TABLE',
} as const;

type Layout = keyof typeof LAYOUTS;

type SessionEntity = {
  // eslint-disable-next-line camelcase
  activities: { scene_name: string; pack: number; variation: number }[];
  session: number;
};

type FreeUnityDataInputs = {
  language: string;
  packOrVariation: string;
  platformUrl: string;
  regionParameters: RegionParameters;
  sceneName: string;
  sessionEntity?: SessionEntity;
  solverUrl?: string;
  stage: string;
  Tokens: {
    AccessToken: string | undefined;
    RefreshToken: string | undefined;
    IdToken: string | undefined;
    clientID: string | undefined;
  };
  userId?: string;
  userToken?: string;
  maxDuration: number;
  minDuration: number;
  minStatements: number;
};

type BuildData = {
  buildDomain?: string;
  buildPath: string;
};

type OptionsSelector = {
  [key: string]: string;
};

interface AllLanguages {
  [key: string]: string[];
}

interface Data {
  allLanguages: AllLanguages;
}

interface ApiResponse {
  data: Data;
}

export type {
  Activity,
  ActivityParameters,
  ActivityState,
  AllLanguages,
  ApiResponse,
  BuildData,
  Data,
  DevParameters,
  Feedback,
  FreeUnityDataInputs,
  InteractiveTableFields,
  Layout,
  MessageType,
  Metrics,
  OptionsSelector,
  Payload,
  RegionParameters,
  ResponseStatusType,
  SessionEntity,
  Statement,
  UnityActivity,
  UserParameters,
  RequestState,
};

export { LAYOUTS, RESPONSE_STATUS_TYPE };
