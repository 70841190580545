import { useQuery } from 'react-query';
import { useMessageContext } from '../providers';
import { Statement } from '../utils/types';
import { apiErrorHandling } from '../utils/api-error-handling';
import { axiosInstance } from '../utils/axios-instance';

const getStatement = async (
  statementId: string,
  activityId: string,
  apiUrl: string,
): Promise<Statement> => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${apiUrl}/statements/${statementId}?activityId=${activityId}`,
    });
    return response.data;
  } catch (error) {
    apiErrorHandling(error, {
      callOrigin: 'getStatement',
      activityId,
      statementId,
    });
    throw error;
  }
};

type Props = {
  statementId: string;
  activityId: string;
  type: string | undefined;
  apiUrl: string;
};

function useGetStatement({ activityId, apiUrl, statementId, type }: Props) {
  const { updateMessageOutput } = useMessageContext();
  const query = useQuery(
    ['statement', statementId],
    () => {
      try {
        return getStatement(statementId, activityId, apiUrl);
      } catch (error: any) {
        updateMessageOutput({
          eventId: 'Error',
          arguments: {
            activityId,
            error: error.response?.data,
          },
        });
        throw new Error(
          error.response?.data?.detail || 'Error loading statement',
        );
      }
    },
    {
      enabled: type !== 'unity' && !!statementId && !!activityId,
      retry: 3,
    },
  );
  return query;
}

export { getStatement, useGetStatement };
